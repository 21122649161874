import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import { StarGroup } from '@magalu/stereo-ui-icons';
import withLayoutProps from '../../../hocs/withLayoutProps';
import ProductBundleSelector from '../ProductBundleSelector';
import VisibilitySection from '../VisibilitySection';
import RichDescription from '../RichDescription';
import GenericButton from '../GenericButton';
import { Content, Description, WarningGenAI } from './ReviewSummary.styles';
var baseTitle = {
  color: 'base',
  fontSize: 2,
  fontWeight: 'bold',
  lineHeight: 'display',
  my: [2, 2, 3, 3]
};
function ReviewSummary(_ref) {
  var data = _ref.data,
    staticProps = _ref.staticProps,
    structure = _ref.structure;
  var product = data.product,
    reviewSummary = data.reviewSummary;
  var _structure$route = structure.route,
    productId = _structure$route.productId,
    slug = _structure$route.slug,
    categoryId = _structure$route.categoryId,
    subCategoryId = _structure$route.subCategoryId;
  if (!(reviewSummary == null ? void 0 : reviewSummary.summary)) {
    return null;
  }
  return React.createElement(VisibilitySection, {
    eventKey: "product:active:section",
    eventValue: "product:detail"
  }, React.createElement(Content, {
    "data-testid": "summary-detail"
  }, staticProps.label && React.createElement(Heading, _extends({}, baseTitle, {
    fontSize: [2, 2, 5, 5],
    fontWeight: "medium",
    "data-testid": "summary-detail-label",
    as: staticProps.labelAs || 'h2'
  }), staticProps.label), React.createElement(ProductBundleSelector, {
    data: product
  }), React.createElement(Description, {
    fontSize: [1, 1, 2, 2],
    "data-testid": "summary-detail-description"
  }, React.createElement(RichDescription, {
    "static": {
      deniedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'a', 'div', 'img', 'svg'],
      richContent: reviewSummary.summary
    }
  })), React.createElement("div", {
    style: _extends({}, WarningGenAI, {
      fontSize: '2'
    })
  }, React.createElement(StarGroup, null), "Resumo das avalia\xE7\xF5es do produto feito por intelig\xEAncia artificial, com base nas avalia\xE7\xF5es de clientes reais"), React.createElement(GenericButton, {
    "static": _extends({}, staticProps.genericButtonProps, {
      href: "/review/".concat(productId, "/").concat(slug, "/").concat(categoryId, "/").concat(subCategoryId),
      target: '_blank'
    })
  })));
}
ReviewSummary.ssr = true;
ReviewSummary.defaultProps = {
  staticProps: {
    genericButtonProps: {
      as: 'a',
      backgroundColor: 'blue',
      borderRadius: 6,
      boxShadow: 'inset 0 0 0 1px #0086ff',
      color: 'white',
      fontSize: '16px',
      full: 'full',
      height: '52px',
      href: '',
      label: 'Ver todas as avaliações',
      lineHeight: 'display',
      margin: '70px 0',
      marginTop: '32px',
      minWidth: '440px',
      size: 'large',
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'inset 0 0 0 1px #0086ff',
        color: '#0086ff',
        height: '52px',
        marginTop: '32px'
      },
      uppercase: false,
      variation: 'outline'
    },
    label: 'O que os clientes estão falando do produto',
    labelAs: 'h2'
  }
};
process.env.NODE_ENV !== "production" ? ReviewSummary.propTypes = {
  data: PropTypes.shape({
    product: PropTypes.shape({
      brand: PropTypes.shape({
        slug: PropTypes.string.isRequired
      }).isRequired,
      category: PropTypes.shape({
        id: PropTypes.string.isRequired
      }).isRequired,
      id: PropTypes.string.isRequired,
      subcategory: PropTypes.shape({
        id: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    reviewSummary: PropTypes.shape({
      summary: PropTypes.string.isRequired
    })
  }).isRequired,
  staticProps: PropTypes.shape({
    genericButtonProps: PropTypes.shape({
      as: PropTypes.string,
      backgroundColor: PropTypes.string,
      borderRadius: PropTypes.number,
      boxShadow: PropTypes.string,
      color: PropTypes.string,
      fontSize: PropTypes.string,
      full: PropTypes.string,
      height: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      lineHeight: PropTypes.string,
      margin: PropTypes.string,
      marginTop: PropTypes.string,
      minWidth: PropTypes.string,
      size: PropTypes.string,
      style: PropTypes.objectOf(PropTypes.any),
      uppercase: PropTypes.bool,
      variation: PropTypes.string
    }),
    label: PropTypes.string,
    labelAs: PropTypes.string
  }),
  structure: PropTypes.shape({
    route: PropTypes.shape({
      categoryId: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      subCategoryId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
} : void 0;
export default withLayoutProps(ReviewSummary);